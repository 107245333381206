const nl = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  yes: `Ja`,
  no: `Nee`,
  home: `Thuis`,
  dashboard: `Dashboard`,
  user: `Gebruiker`,
  account: `Account`,
  general: `Algemeen`,
  settings: `Instellingen`,
  username: `Gebruikersnaam`,
  login: `Inloggen`,
  logout: `Uitloggen`,
  emailAddress: `E-mailadres`,
  password: `Wachtwoord`,
  newPassword: `Nieuw wachtwoord`,
  oldPassword: `Oud wachtwoord`,
  confirmNewPassword: `Nieuw wachtwoord bevestigen`,
  updatePassword: `Wachtwoord bijwerken`,
  formErrorValidEmail: `E-mail moet een geldig e-mailadres zijn`,
  formErrorEmailRequired: `E-mail is vereist`,
  formErrorPasswordRequired: `Wachtwoord is vereist`,
  formErrorOldPasswordRequired: `Oud wachtwoord is vereist`,
  formErrorPasswordLength: `Wachtwoord moet minstens 6 tekens bevatten`,
  formErrorConfirmPasswordRequired: `Bevestigen van het wachtwoord is vereist`,
  formErrorPasswordsMatch: `Wachtwoorden moeten overeenkomen`,
  formForgotPasswordLink: `Wachtwoord vergeten?`,
  snackBarChangePasswordSuccess: `Wachtwoord succesvol wijzigen!`,
  snackBarUpdateSuccess: `De update is geslaagd!`,
  active: `Actief`,
  inactive: `Inactief`,
  on: `Aan`,
  off: `Uit`,
  billing: `Facturering`,
  description: `Beschrijving`,
  amount: `Bedrag`,
  status: `Status`,
  changePassword: `Wachtwoord wijzigen`,
  changeSettings: `Instellingen veranderen`,
  setup: `VPN instellen`,
  free: `Gratis`,
  invoices: `Facturen`,
  cancelSubscriptionButton: `Abonnement opzeggen`,
  date: 'Datum',
  download: 'Downloaden',
  print: 'Printen',
  close: 'Sluiten',
  view: 'Bekijken',
  warningBanner: {
    text: 'U heeft geen actief account. Om uw account te activeren, vernieuwt u deze door op de knop te klikken.',
    ctaButton: 'Vernieuwen',
    caption: 'Ondertiteling'
  },
  loginPage: {
    title: `Inloggen`,
    formTitle: `Nieuw bij VPN Nederland? Account aanmaken`,
    formTitleLink: `hier`,
    formForgotPasswordLink: `Wachtwoord vergeten?`
  },
  resetPasswordPage: {
    title: `Wachtwoord opnieuw instellen`,
    formTitle: `Wachtwoord vergeten?`,
    formSubTitle: ` Voer het e-mailadres in dat aan je account is gekoppeld en we sturen je z.s.m. een link per e-mail.`,
    formTitleLink: `Keer terug om in te loggen`,
    formButton: `Verzoek verzenden`,
  },
  newPasswordPage: {
    title: `Wachtwoord opnieuw instellen`,
    formTitle: `Verzoek succesvol verzonden!`,
    formSubTitle: `We hebben een e-mail met bevestigingslink verzonden.`,
    formSubTitle2: `Ga naar de link om je e-mail te verifiëren.`,
    formTitleLink: `Keer terug om in te loggen`,
    formButton: `Verzoek verzenden`,
  },
  accountPage: {
    title: `Account`,
    accountStatus: `Accountstatus`,
    accountType: `Accounttype`,
    renewAccount: `Account verlengen`,
    endDate: `Eind-datum`,
    subRemaining: `Abonnement resterend:`,
    autoRenew: `Automatisch verlengen`,
    vpnCredentials: `VPN credentials:`,
    setupVpn: `Instellen VPN`,
    nextBillingDate: `Volgende factuurdatum`,
    endOfFreeTerm: `Eind van de gratis termijn`,
    cancelSubscription: `Annuleer abonnement`,
    activationCode: `Activatie code`,
    newsNotifications: `Nieuws en notificaties e-mails`,
    recurringSubscription: `Abbonement`,
    tabs: {
      general: `Algemeen`,
      settings: `Instellingen`,
      changePassword: `Wachtwoord wijzigen`
    },
  },
  settingsPage: {
    title: `Instellingen`,
    formTitle: `Meldingen en nieuwsbrieven`,
    recurringPayment: ` Terugkerende betaling`,
    yourSubscription: `Je Abonnement`,
    per: `Per`,
    curSubs: `Het huidige abonnement loopt door tot`,
    warn: `Je account wordt niet meer maandelijks verlengd.`,
    submitButton: `Wijzigingen opslaan`,
    settingsOption1: `Nieuws en aankondigingen`
  },
  promoPage: {

    tabH1: `Congratulations and Welcome!. Your Traffic limit has been upgraded. Enjoy 5GB monthly usage. `,
    tabH2: `You just need to download, everything is filled in for you`,
  },
  setupVpnPage: {
    title: `Instellen VPN`,
    header: `Download VPN Nederland voor {{os}}`,
    coverH1: `Download de app met één druk op de knop`,
    coverH2: `Je hoeft alleen maar te downloaden, alles is al voor je ingevuld`,
    coverH3: `Ook beschikbaar voor`,
    coverButtonText: 'Downloaden voor ',
    tabH1: `De activatiecode is al voor je ingevuld en je hoeft VPN Nederland alleen te downloaden. Installeer je het liever handigmatig? Bekijk dan de stappen hieronder om te zien hoe je dit doet.`,
    tabH2: `Liever handmatig installeren?`,
    step1: `Downloaden`,
    step1Caption: `Wil je VPN Nederland instellen, maar moet je de software nog downloaden? Klik dan op onderstaande knop om de download te beginnen.`,
    step2: `Kopiëren`,
    step2Caption: `Kopieer de activatiecode en gebruik deze tijdens het instellen van de software.`,
    step3: `Plakken`,
    step3Caption: `Plak de gekopieerde activatiecode in de software om de installatie van VPN Nederland te voltooien.`
  },
  cancelSubscription: {
    h1: `Je kunt je abonnement hieronder`,
    h1br:`makkelijk opzeggen`,
    sub1: `Fijn dat je lid bent geweest. Je bent vanzelfsprekend altijd weer welkom.`,
    sub1br: `Hieronder kun je je opzegging voltooien.`,
    cardTitle: `Abonnement opzeggen`,
    cardText: `Weet je zeker dat je je online privacy op wilt geven? Gebruik dan
                  onderstaande knop om het opzeggen van je abonnement te voltooien.`,
    cancelButton: `Opzegging voltooien`,
    backButton: `Terug naar website`,
    cardCaption: `De opzegging gaat in aan het einde van je huidige facturerings-periode`,
    cardCaptionBr: `op`,
    h2: `Waarom blijven bij VPN Nederland?`,
    p1: `100% Privacy`,
    p1c: `Met VPN Nederland zijn alle zorgen om je online privacy  verleden tijd. Niemand
                    kan meekijken met jouw data en je kunt je online volledig vrij bewegen.`,
    p2: `100% Veiligheid`,
    p2c: `Doordat een VPN jouw verbinding versleutelt, is het voor hackers niet meer
mogelijk om in te breken. Zo voorkom je dat ze er vandoor gaan met jouw data.`,
    p3: `100% Vrijheid`,
    p3c: `Met VPN Nederland kun je geo-blokkades omzeilen en doen alsof je in een
ander land bent, zo geniet je van 100% online vrijheid.`,
    p4: `100% Streaming`,
    p4c: `Met VPN Nederland krijg je toegang tot elke (buitenlandse) streamingdienst.
Pluspuntje; Onze supersnelle verbindingen zijn perfect voor streaming!`,
    p5: `100% Downloaden`,
    p5c: `Met de supersnelle servers en moderne protocollen van VPN Nederland kan je overal ter wereld moeiteloos downloaden en streamen.`,
    exButton: `Online veilig blijven`,
    h1Canceled: `Je hebt je abonnement succesvol opgezegd.`,
    h1CanceledFree: `Je hebt je gratis abonnement succesvol opgezegd.`,
    sub1Canceled: `Fijn dat je lid bent geweest. Je bent vanzelfsprekend altijd weer welkom.`,
    sure: `Weet je het zeker?`,
    happy: `Wij zien u graag nog eens terug.`
  },
  verifyEmailPage: {
    title: `Verifieer Email`,
    checkYourEmail: `Controleer alstublieft uw e-mail!`,
    inProgress: `Verificatie bezig...`,
    bottomText: `Alle informatie over je abonnement en de app vind je in je inbox.`,
    success: `E-mail geverifieerd!`,
    alreadyVerified: 'E-mail al geverifieerd',
    button: `Bekijk je account`,
    p1: `U heeft gekozen voor VPN Nederland`,
    p2: `Je bent succesvol geregistreerd`,
    p3: `Uw e-mailadres is succesvol geverifieerd`,
    p4: `Uw VPN Nederland abonnement is geactiveerd.`,
  },
  billingPage: {
    paymentFor: `Betaling voor`,
    invoiceNumber: `Factuurnummer`
  },
  invoiceDetailsPage: {
    title: `Factuur`,
    email: 'E-mailadres',
    customerNumber: 'Klantnummer',
    invoiceFrom: 'Factuur van',
    invoiceTo: 'Factuur aan',
    invoiceDate: 'Factuur datum',
    invoicePaymentMethod: 'Betalingswijze',
    invoiceDescription: 'Beschrijving',
    invoiceProductQty: 'Aant',
    invoiceProductPrice: 'Prijs',
    invoiceTotal: 'Totaal',
    invoiceSubtotal: 'Subtotaal',
    invoiceVat: 'Belastingen(BTW)',
    supportDescription: 'Heb je een vraag?',
    status: {
      open: 'Open',
      paid: 'voldaan',
      canceled: 'geannuleerd',
      pending: 'in behandeling',
      chargeback: 'terugvordering',
      expired: 'verlopen',
      failed: 'mislukt',
      settled: 'afgehandeld',
      partially_refunded: 'deels terugbetaald',
      refunded: 'terugbetaald',
      authorized: 'geautoriseerd',
      internal_error: 'interne fout',
    }
  },
  maintenancePage: {
    h1: `We doen wat onderhoud.`,
    h2: `Zo terug!`,
  },
  page404: {
    h1: `Oeps… de pagina die je zoekt blijkt niet te bestaan.`,
    p: `Verdwaald geraakt tijdens je zoektocht naar de Nederlandse VPN-provider? Geen zorgen, wij brengen je er heen!`,
    btn: `Breng me naar de beste VPN!`,
  }
};

export default nl;
