import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {AppBar, Toolbar, Box, Link, Stack} from '@mui/material';
// config
import { HEADER } from '../../config';
// utils
import { bgBlur } from '../../utils/cssStyles';
// routes
import {PATH_DASHBOARD, PATH_PAGE, PATH_AUTH} from '../../routes/paths';
// components
import Logo from '../../components/logo';
import {useLocales} from "../../locales";
import LanguagePopover from "../dashboard/header/LanguagePopover";


// ----------------------------------------------------------------------

Header.propTypes = {
  isOffset: PropTypes.bool,
};

export default function Header({ isOffset }) {
  const theme = useTheme();
    const {translate} = useLocales();
  return (
    <AppBar position='relative' color="transparent" sx={{ boxShadow: 0, borderBottom:'1px solid #eaeaf5' }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
          <Stack >
              <Logo />
          </Stack>

<Stack direction='row' spacing={2}>
    <LanguagePopover />
    <Link to={PATH_DASHBOARD.user.account} component={RouterLink} variant="subtitle2" color="inherit" sx={{display: 'flex', alignItems: 'center'}}>
        {translate('account')}
    </Link>
    {/*<Link to={PATH_PAGE.faqs} component={RouterLink} variant="subtitle2" color="inherit">*/}
    {/*     Support*/}
    {/*</Link>*/}
    <Link to={PATH_AUTH.login} component={RouterLink} variant="subtitle2" color="inherit" sx={{display: 'flex', alignItems: 'center'}}>
        {translate('login')}
    </Link>
</Stack>

      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
