// ----------------------------------------------------------------------

import {HOST_API_KEY} from "../config";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USER = '/user'
const ROOT_PROMO = '/promo'
// ----------------------------------------------------------------------
export const PATH_USER = {
  root: ROOTS_USER,
  setup: path(ROOTS_USER, '/setup-vpn'),

};
export const PATH_PROMO = {
  upgrade: path(ROOT_PROMO, '/upgrade'),
}
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  confirm: path(ROOTS_AUTH, '/confirm')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',

  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',

};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,


  user: {
    root: path(ROOTS_DASHBOARD, '/user'),

    account: path(ROOTS_DASHBOARD, '/user/account'),
    changePassword: path(ROOTS_DASHBOARD, '/user/change_password'),
    cancelSubscription: path(ROOTS_DASHBOARD, '/user/cancel_subscription'),
    settings: path(ROOTS_DASHBOARD, '/user/settings'),
    invoices: path(ROOTS_DASHBOARD, '/user/invoices'),
    invoicesView:(id)=> path(ROOTS_DASHBOARD, `/user/invoices/${id}/view`),

  },



};
export const PATH_HOMEPAGE = 'https://www.vpnnederland.nl/';

export const PATH_SETUP_VPN_DEV = 'https://my.vpnnederland.nl/user/setup-vpn';
