import * as Yup from 'yup';
// form
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
// @mui
import {IconButton, InputAdornment, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// components
import Iconify from '../../../../components/iconify';
import {useSnackbar} from '../../../../components/snackbar';
import FormProvider, {RHFTextField} from '../../../../components/hook-form';
import {useState} from "react";
import {useAuthContext} from "../../../../auth/useAuthContext";
import {useLocales} from "../../../../locales";

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
    const {changePassword} = useAuthContext();
    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales();
    const [showPassword, setShowPassword] = useState(false);
    const ChangePassWordSchema = Yup.object().shape({
        oldPassword: Yup.string().required(translate('formErrorOldPasswordRequired')),
        newPassword: Yup.string().min(6, translate('formErrorPasswordLength')).required(translate('formErrorPasswordRequired')),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], translate('formErrorPasswordsMatch')),
    });

    const defaultValues = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const methods = useForm({
        resolver: yupResolver(ChangePassWordSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: {isSubmitting},
    } = methods;

    const onSubmit = async ({newPassword, oldPassword}) => {

        try {
            await changePassword(newPassword, oldPassword);
            reset();
            enqueueSnackbar(translate('snackBarUpdateSuccess'));
        } catch (error) {
            enqueueSnackbar(error.message, {variant: "error"});
        }
    };

    return (

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

            <Stack spacing={3} alignItems="flex-end" sx={{p: 3}}>
                <RHFTextField name="oldPassword" type={showPassword ? 'text' : 'password'} label={translate('oldPassword')}

                              InputProps={{
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                          </IconButton>
                                      </InputAdornment>
                                  ),
                              }} />

                <RHFTextField
                    name="newPassword"
                    type={showPassword ? 'text' : 'password'}
                    label={translate('newPassword')}
                    helperText={
                        <Stack component="span" direction="row" alignItems="center">
                            <Iconify icon="eva:info-fill" width={16} sx={{mr: 0.5}} />{translate('formErrorPasswordLength')}
                        </Stack>
                    }


                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <RHFTextField name="confirmNewPassword" type={showPassword ? 'text' : 'password'} sx={{fontSize: "14px"}}
                              InputLabelProps={{
                                  sx: {fontSize: "14px"}
                              }}
                              label={translate('confirmNewPassword')} InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }} />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{
                    mt: 3,
                    color: '#ffffff',
                    padding: '12px 18px;'
                }}>
                    {translate('settingsPage.submitButton')}
                </LoadingButton>
            </Stack>

        </FormProvider>

        //     </Card>
        //   </Grid>
        // </Grid>
    );
}
