import PropTypes from 'prop-types';
// @mui
import {Typography, Stack, AppBar, Toolbar, Link, Icon, Button, Divider, IconButton} from '@mui/material';
//

import Iconify from "../iconify";
import {alpha, styled, useTheme} from "@mui/material/styles";
import {CHECKOUT_URL, HEADER} from "../../config";

import {PATH_AUTH, PATH_DASHBOARD, PATH_PAGE} from "../../routes/paths";
import {Link as RouterLink} from "react-router-dom";
import {useLocales} from "../../locales";
import {useAuthContext} from "../../auth/useAuthContext";
import {useEffect, useState} from "react";
import {getOs} from "../../utils/getOs";

// ----------------------------------------------------------------------

WarningBanner.propTypes = {
  sx: PropTypes.object,
  img: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
const StyledButton = styled((props) => <Button {...props} />)({
    // paddingTop: 14,
    // paddingRight: 10,
    // paddingBottom: 14,
    // paddingLeft: 30,
    borderRadius: 6,
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: '#F37366',
    // boxShadow: '0px 4px 0px 0px #c55c01',
    border: '1px solid #ffff',
    "&:hover": {
        // boxShadow: '0px 4px 0px 0px #c55c01',
        // border: '2px solid transparent',
        backgroundColor: '#F37366',
    }
});
export default function WarningBanner({ title,position, description, img, sx, ...other }) {
    const theme = useTheme();
    const { translate } = useLocales();
    const {user} = useAuthContext()
    const [isActive, setActive] = useState(1);

    const handleRenewClick = () => {
        window.open(CHECKOUT_URL +`?email=${user?.email}`, '_blank')
    }
    const onBannerClose = ()=> {
        setActive(0)
    }
    useEffect(() => {
        if(user && user.status) {
            setActive(user?.status);
        }

    }, [user?.status]);
    return (
        <>{!isActive ?
            <AppBar position={position} color="transparent" sx={{ boxShadow: 0,top: 0, }}>
            <Toolbar
                sx={{
                    justifyContent: 'space-between',
                    height: {
                        xs: HEADER.H_MOBILE,
                        // md: HEADER.H_MAIN_DESKTOP,
                    },

                    bgcolor: '#F37366',
                    // transition: theme.transitions.create(['height', 'background-color'], {
                    //     easing: theme.transitions.easing.easeInOut,
                    //     duration: theme.transitions.duration.shorter,
                    // }),

                }}
            >
                <Stack direction='row' spacing={2}>
                    <Iconify icon="mdi:warning-circle-outline"  sx={{width: 14}} color="#ffff"/>
                    <Typography sx={{fontSize: '14px', color: '#ffff'}}>{translate('warningBanner.text')}</Typography>
                </Stack>

                <Stack direction='row' spacing={2} alignItems="center">


                    <StyledButton style={{fontFamily: "Montserrat, sans-serif"}} variant="contained" onClick={()=>handleRenewClick()}>
                        {translate(`warningBanner.ctaButton`)}

                    </StyledButton>
                    {/*<Divider orientation="vertical"  />*/}
                    <Divider orientation="vertical" flexItem color='#F9B4AD' />
                    <IconButton
                        size="small"
                        onClick={()=>onBannerClose()}
                    >
                        <Iconify icon="fa6-regular:circle-xmark" sx={{width: 14}}  color="#ffff" />
                    </IconButton>


                </Stack>

            </Toolbar>

            {/*{isOffset && <Shadow />}*/}
        </AppBar> : null}
            </>

    );
}
