import {Navigate, useRoutes} from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
//
import {
    ConfirmEmailPage,
    ConfirmEmailPageOld,
    HomePageTransfer,
    InvoiceDetailsPage,
    InvoiceListPage,
    LoginPage,
    MaintenancePage,
    NewPasswordPage,
    Page403,
    Page404,
    Page500,
    ResetPasswordPage,
    SetupVpnPage,
    SetupVpnPageOld,
    UserAccountPage,
    VerifyCodePage,
    PromoUpgradeFree
} from './elements';
import UserChangePasswordPage from "../pages/dashboard/UserChangePasswordPage";
import UserSettingsPage from "../pages/dashboard/UserSettingsPage";
import UserCancelSubscriptionPage from "../pages/dashboard/UserCancelSubscriptionPage";



// ----------------------------------------------------------------------

export default function Router() {

    return useRoutes([
        // MaintenancePage
        {
            element: <CompactLayout />,
            children: [

                {path: 'maintenance', element: <MaintenancePage />},
                {path: '500', element: <Page500 />},
                {path: '404', element: <Page404 />},
                {path: '403', element: <Page403 />},
            ],
        },
        {path: '/', element: <HomePageTransfer />},
        {path: '*', element: <Navigate to="/404" replace />, index: true},

        {
            element: <SimpleLayout />,
            children: [
                {path: 'setup-vpn/:id/:code', element: <SetupVpnPageOld />},
            ],
        },
        {
            element: <CompactLayout />,
            children: [
                {path: 'email-verification/:id/:code', element: <ConfirmEmailPageOld />},
                {path: 'promo/upgrade/:id/:code', element: <PromoUpgradeFree />}
            ],
        },

        {

            children: [
                {
                    path: 'auth/login',
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    ),
                },

                {
                    element: <CompactLayout />,
                    children: [
                        {path: 'auth/reset-password', element: <ResetPasswordPage />},
                        {path: 'auth/new-password', element: <NewPasswordPage />},
                        {path: 'auth/verify', element: <VerifyCodePage />},
                        {path: 'auth/confirm', element: <ConfirmEmailPage />},
                    ],
                },
            ],
        },
        {
            element: <SimpleLayout />,
            children: [
                {path: 'user/setup-vpn', element: <SetupVpnPage />},
            ],
        },
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),


            children: [
                {
                    children: [
                        {path: 'user/account', element: <UserAccountPage />},
                        {path: 'user/settings', element: <UserSettingsPage />},
                        {path: 'user/cancel_subscription', element: <UserCancelSubscriptionPage />},
                        {path: 'user/change_password', element: <UserChangePasswordPage />},
                        {path: 'user/invoices', element: <InvoiceListPage />},
                        {path: 'user/invoices/:id/view', element: <InvoiceDetailsPage />},
                        {path: '*', element: <Navigate to="/404" replace />},
                        {path: '', element: <Navigate to="/404" replace />}
                    ],
                },

            ],
        },


        // { path: '*', element: <Navigate to="/404" replace /> },

    ]);
}
