import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback } from 'react';
// utils
import axios from '../utils/axios';
//
import {isValidToken, removeSession, setSession} from './utils';
import {PATH_AUTH} from "../routes/paths";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "../components/snackbar";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'GET_AUTH_CODE') {
    const {payload} = action
    return {
      ...state,
      user: {...state.user, ...payload}


    };
  }
  if (action.type === 'SET_LANGUAGE_SUCCESS') {
    const {payload} = action
    return {
      ...state,
      ...payload


    };
  }
  if (action.type === 'CANCEL_SUCCESS') {
    const {payload} = action
    return {
      ...state,
      ...payload


    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get('/user/profile');

        const  user  = {...response.data};

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (email, password) => {
    const response = await axios.post('/auth/login', {
      email,
      password,
    });
    const { jwtData, user } = response.data;


    setSession(jwtData);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  // REGISTER
  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  // LOGOUT
  const logout = async () => {
    removeSession();
    dispatch({
      type: 'LOGOUT',
    });
  };
  // RESET PASSWORD
  const resetPassword = async (email) => {


    const response = await axios.post('/user/reset-password', {
      email,
    });

  };
  const resetPasswordConfirm = async ({password, email, token}) => {


  const response = await axios.post('/user/reset-password-confirm', {
    password, email, token
  });



  const {jwtData, user} = response.data;
  const {accessToken, refreshToken} = jwtData;

  setSession({accessToken, refreshToken});

  dispatch({
    type: 'LOGIN',
    payload: {
      user,
    },
  });

  };
  const changePassword = async (newPassword, oldPassword) => {


    const response = await axios.post('/user/change-password', {
      oldPassword,
      newPassword
    });

    // const { jwtData, user } = response.data;
    // const {accessToken} = jwtData;
    //
    // setSession(accessToken);

    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user,
    //   },
    // });
  };
  const confirmEmailOld = async (userID, token) => {


    const response = await axios.post('/user/email-confirm-outdated', {
      userID, token
    });
    const {jwtData, user} = response.data;
    setSession(jwtData);
    //
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });

  };
  const confirmEmail = async ( token) => {


    const response = await axios.post('/user/email-confirm', {
        token
    });
    if(response && response.data?.user) {

      const {jwtData, user} = response.data;
      setSession(jwtData);
      //
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

      return "success"
    } else {
      return "alreadyVerified"
    }


  };

  const getVpnToken = async (id, token) => {

    const response = await axios.post('/user/setup-vpn', {
      id:Number(id), setupVpnToken:token
    });
    const {vpnAuthCode, status} = response.data
    // console.log(response.data)
    // setSession(jwtData);
    //
    if(vpnAuthCode) {
      dispatch({
        type: 'GET_AUTH_CODE',
        payload: {
          vpnAuthCode,
          status
        },
      });
    }



  };
  const getVpnTokenOld = async (id, token) => {

    const response = await axios.post('/user/setup-vpn-outdated', {
      id:Number(id), setupVpnToken:token
    });
    const {vpnAuthCode, status} = response.data
    // console.log(response.data)
    // setSession(jwtData);
    //
    if(vpnAuthCode) {
      dispatch({
        type: 'GET_AUTH_CODE',
        payload: {
          vpnAuthCode,
          status
        },
      });
    }

  };

  const promoUpgrade = async (id, token) => {

    const response = await axios.post('/payment/upgraded-2gb-users', {
      id:Number(id), hash:token.toString()
    });

    return response.data
  };


  const setLanguage = async (language) => {


    const response = await axios.post('/user/change-language', {
      language
    });

    // const {jwtData, user} = response.data;
    // setSession(jwtData);
    //
    dispatch({
      type: 'SET_LANGUAGE_SUCCESS',
      payload: {
        user: response.data,
      },
    });

  };

  const cancelSubscription = async (email) => {


    const response = await axios.post('/payment/cancel-subscription', {
      email
    });

    // const {jwtData, user} = response.data;
    // setSession(jwtData);
    //
    dispatch({
      type: 'CANCEL_SUCCESS',
      payload: {
        user: response.data,
      },
    });



  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        resetPasswordConfirm,
        changePassword,
        confirmEmail,
        getVpnToken,
        setLanguage,
        cancelSubscription,
        confirmEmailOld,
        getVpnTokenOld,
        promoUpgrade,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
