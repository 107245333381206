import { Helmet } from 'react-helmet-async';
import {useEffect, useState} from 'react';
// @mui
import {Container, Tab, Tabs, Box, Grid, Card} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// _mock_

// components
import Iconify from '../../components/iconify';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
// sections
import {
  AccountGeneral,

  AccountChangePassword,
} from '../../sections/@dashboard/user/account';
import {useAuthContext} from "../../auth/useAuthContext";
import AccountSettings from "../../sections/@dashboard/user/account/AccountSettings";
import {useLocales} from "../../locales";


// ----------------------------------------------------------------------

export default function UserSettingsPage() {
  const { translate} = useLocales();
  const { themeStretch } = useSettingsContext();
  const {user} = useAuthContext();




  return (
    <>
      <Helmet>
        <title> {translate(`accountPage.title`)}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={translate(`accountPage.title`)}
          links={[
            { name: translate(`dashboard`), href: PATH_DASHBOARD.user.account, },
            { name: translate(`user`), href: PATH_DASHBOARD.user.account, },
            { name: translate(`settings`)},]
          }
        />
        <Grid container spacing={3}>

          <Grid item xs={12} md={8}>
            <Card sx={{p: 3}}>
        <AccountSettings user={user} />
            </Card>
          </Grid>
        </Grid>


      </Container>
    </>
  );
}
