import {Helmet} from 'react-helmet-async';
// @mui
import {Card, Container, Grid} from '@mui/material';
// routes
import {PATH_DASHBOARD} from '../../routes/paths';
// _mock_
// components
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import {useSettingsContext} from '../../components/settings';
// sections
import {AccountChangePassword,} from '../../sections/@dashboard/user/account';

import {useLocales} from "../../locales";

// ----------------------------------------------------------------------

export default function UserChangePasswordPage() {
    const {translate} = useLocales();
    const {themeStretch} = useSettingsContext();




    return (
        <>
            <Helmet>
                <title> {translate(`accountPage.title`)}</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'lg'}>
                <CustomBreadcrumbs
                    heading={translate(`accountPage.title`)}
                    links={[
                        {name: translate(`dashboard`), href: PATH_DASHBOARD.user.account,},
                        {name: translate(`user`), href: PATH_DASHBOARD.user.account,},

                        {name: translate(`changePassword`)},]
                    }
                />
                <Grid container spacing={3}>

                    <Grid item xs={12} md={8}>
                        <Card sx={{p: 3}}>
                            <AccountChangePassword />
                        </Card>
                    </Grid>
                </Grid>


            </Container>
        </>
    );
}
