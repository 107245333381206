// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import {ThemeSettings} from './components/settings';
import {MotionLazyContainer} from './components/animate';
import * as Sentry from "@sentry/react";


// ----------------------------------------------------------------------
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-5L2L8W9D'
}

TagManager.initialize(tagManagerArgs)


// ----------------------------------------------------------------------
Sentry.init({
    dsn: "https://97a44dc10f218c324eb80c1488ac9680@o4506376939569152.ingest.us.sentry.io/4506396237430784",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled

        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App() {
    return (
        <MotionLazyContainer>
            <ThemeProvider>
                <ThemeSettings>
                    <ThemeLocalization>
                        <SnackbarProvider>
                            <Router />
                        </SnackbarProvider>
                    </ThemeLocalization>
                </ThemeSettings>
            </ThemeProvider>
        </MotionLazyContainer>
    );
}
