import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';
import {PATH_HOMEPAGE} from "./paths";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
// OLD
export const ConfirmEmailPageOld = Loadable(lazy(() => import('../pages/auth/ConfirmEmailPageOld')));
export const SetupVpnPageOld = Loadable(lazy(() => import('../pages/auth/SetupVpnPageOld')));
export const HomePageTransfer = () => {
    window.location.href = PATH_HOMEPAGE;
    return null;
}
// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const ConfirmEmailPage = Loadable(lazy(() => import('../pages/auth/ConfirmEmailPage')));
export const SetupVpnPage = Loadable(lazy(() => import('../pages/auth/SetupVpnPage')));
export const PromoUpgradeFree = Loadable(lazy(() => import('../pages/auth/PromoUpgradeFree')));



// DASHBOARD: INVOICE
export const InvoiceListPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceListPage')));
export const InvoiceDetailsPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetailsPage')));


// DASHBOARD: USER

export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));




// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));



export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

