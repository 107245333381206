// form
import { useForm } from 'react-hook-form';
// @mui
import {Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSwitch } from '../../../../components/hook-form';
import {format} from "date-fns";
import {useLocales} from "../../../../locales";

// ----------------------------------------------------------------------





const NOTIFICATION_SETTINGS = {
  applicationNews: true,

};

// ----------------------------------------------------------------------

export default function AccountSettings(props) {

  const {translate} = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const {user} = props
  const {recurringDuration, dateClose} = user
  const APPLICATION_OPTIONS = [
    { value: 'applicationNews', label: translate(`settingsPage.settingsOption1`) },

  ];
  const defaultValues = {
    activityComments: NOTIFICATION_SETTINGS.activityComments,
    activityAnswers: NOTIFICATION_SETTINGS.activityAnswers,
    activityFollows: NOTIFICATION_SETTINGS.activityFollows,
    applicationNews: NOTIFICATION_SETTINGS.applicationNews,
    applicationProduct: NOTIFICATION_SETTINGS.applicationProduct,
    applicationBlog: NOTIFICATION_SETTINGS.applicationBlog,
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar(translate(`snackBarUpdateSuccess`));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {/*<Card sx={{ p: 3 }}>*/}
        <Typography variant="overline" component="div" sx={{ color: 'text.secondary' }}>
          {translate(`settingsPage.formTitle`)}
        </Typography>

        <Stack alignItems="flex-start" spacing={1} sx={{ mt: 2 }}>
          {APPLICATION_OPTIONS.map((application) => (
              <RHFSwitch key={application.value} name={application.value} label={application.label} sx={{ m: 0 }} />
          ))}
        </Stack>

        <Typography variant="overline" component="div" sx={{ color: 'text.secondary', mt: 5 }}>
          {translate(`settingsPage.recurringPayment`)}
        </Typography>

        <Stack alignItems="flex-start" spacing={1} sx={{ mt: 2, mb: 5 }}>
          <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
            {translate(`settingsPage.yourSubscription`)} <br/>
            {translate(`settingsPage.per`)} {recurringDuration} - DirectDebit <br/>
            {translate(`settingsPage.curSubs`)} <Typography variant="subtitle2" component="span" sx={{ color: 'text.primary' }}> {format(new Date(dateClose), "MMMM d yyyy")}.</Typography> <br/>
            {translate(`settingsPage.warn`)}
          </Typography>
          <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="On"
                name="radio-buttons-group"
            >
              <FormControlLabel value="On" control={<Radio />} label={translate(`on`)} />
              <FormControlLabel value="Off" control={<Radio />} label={translate(`off`)} />

            </RadioGroup>
          </FormControl>
        </Stack>

        <Stack>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{ ml: 'auto' }}>
            {translate(`settingsPage.submitButton`)}
          </LoadingButton>
        </Stack>
      {/*</Card>*/}
    </FormProvider>
  );
}
