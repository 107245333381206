import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import billingReducer from './slices/billing'
// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['billing'],
};


const rootReducer = combineReducers({
    billing: billingReducer,
    // product: persistReducer(productPersistConfig),
});

export {rootPersistConfig, rootReducer};
