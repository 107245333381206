// @mui
import { enUS, frFR, zhCN, viVN, arSD, nlNL} from '@mui/material/locale';


// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Dutch',
    value: 'nl',
    systemValue: nlNL,
    icon: '/assets/icons/flags/ic_flag_nl.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },


];

export const defaultLang = allLangs[0]; // English
