// @mui
import { Button} from '@mui/material';
import {styled} from "@mui/material/styles";

// ----------------------------------------------------------------------

const StyledButton = styled((props) => <Button {...props} />)({
    paddingTop: 14,
    // paddingRight: 45,
    paddingBottom: 14,
    // paddingLeft: 30,
    borderRadius: 3,
    color: '#FFFFFF',
    fontWeight: 600,
    // backgroundColor: '#FD8017',
    width: '100%',
    textTransform: "none",
    // boxShadow: '0px 4px 0px 0px #c55c01',
    // border: '2px solid transparent',
    "&:hover": {
        // boxShadow: '0px 4px 0px 0px #c55c01',
        // border: '2px solid transparent',
    }});

export default StyledButton
