function getOs() {
    const os = ['Windows','Mac', 'Android', 'Ios']; // add your OS values
    const userOs = os.find(v=>navigator.appVersion.indexOf(v) >= 0);
    return userOs || 'Windows';
}
const OsObject = {
    Windows: {
        headerText: 'VPN Nederland voor Windows downloaden',
        captionText: ' iOS, Android, Mac',
        buttonText: ' Windows',
        img: 'Windows',
        icon: 'game-icons:pc',
        downloadLink: "https://update.vpnnederland.nl/windows/VPNNederland-Setup.exe"

    },
    Mac: {
        headerText: 'VPN Nederland voor Mac downloaden',
        captionText: ' iOS, Android, Windows',
        buttonText: ' Mac',
        img: 'Mac',
        icon: 'ic:outline-laptop-mac',
        downloadLink: "https://update.vpnnederland.nl/mac/VPNNederland-MacOS-setup.pkg"
    },
    Android: {
        headerText: 'VPN Nederland voor Android downloaden',
        captionText: ' iOS, Mac, Windows',
        buttonText: ' Android',
        img: 'Android',
        icon: 'ic:outline-tablet-android',
        downloadLink: 'https://play.google.com/store/apps/details?id=com.curuno.vpnnederland'
    },
    Ios: {
        headerText: 'VPN Nederland voor Ios downloaden',
        captionText: ' Android, Mac, Windows',
        buttonText: 'Ios',
        img: 'Ios',
        icon: 'wpf:iphone',
        downloadLink: 'https://apps.apple.com/nl/app/vpn-nederland/id1450814725'
    }
}

export {getOs, OsObject}
