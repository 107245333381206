import axios from 'axios';
// config
import { HOST_API_KEY } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken')

    if(refreshToken){
        axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`
        const response = await axios.post(HOST_API_KEY +'auth/refresh' );

        return response.data
    }

};
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );
axiosInstance.interceptors.request.use(

    async config => {

        const accessToken = localStorage.getItem('accessToken')
        config.headers = {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded'
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });
axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const {accessToken} = await refreshToken()

        if(accessToken){
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
            localStorage.setItem('accessToken', accessToken)
            return axiosInstance(originalRequest);
        }

    }
    return Promise.reject(error);
});




export default axiosInstance;
