import { Helmet } from 'react-helmet-async';
import { useState} from 'react';
// @mui
import {
  Container,
  Grid,
  Card,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText, DialogActions
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// _mock_

// components
import Iconify from '../../components/iconify';

import { useSettingsContext } from '../../components/settings';
// sections

import {useAuthContext} from "../../auth/useAuthContext";

import {useLocales} from "../../locales";

import {Link as RouterLink, useLocation} from "react-router-dom";
import {format} from "date-fns";
import {StyledButton} from "../../components/styled-button";


// ----------------------------------------------------------------------



export default function UserCancelSubscriptionPage() {
  const { translate} = useLocales();
  const { themeStretch } = useSettingsContext();
  const {user, cancelSubscription} = useAuthContext();

  const {email, recurringSubscription, isFreeAccount} = user

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {

    // setCanceled(true);

    await cancelSubscription(email)
    setOpen(false);
  };



  return (
    <>
      <Helmet>
        <title> {translate(`accountPage.title`)}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'} sx={{paddingLeft: {lg:9}, paddingRight: {lg: 9}, fontFamily: 'Montserrat, sans-serif;'}}>

        <Grid container columnSpacing={8} direction={{sm: 'column', md: 'row'}} >
          {!recurringSubscription ?
         ( <Grid item xs={6}>
            <Stack>
              <Typography sx={{fontSize: 20, fontWeight: 600, fontFamily: 'Montserrat, sans-serif;', lineHeight: '1.3'}}>
                {isFreeAccount ? translate('cancelSubscription.h1CanceledFree') : translate('cancelSubscription.h1Canceled')}


              </Typography>
              <Typography sx={{fontSize: '13px',fontWeight: 400,lineHeight: '18px', fontFamily: 'Open Sans, sans-serif;', paddingTop: 1}}>
                {translate('cancelSubscription.sub1Canceled')}

              </Typography>
              <Grid item md={6} sm={12} sx={{marginTop: 5}}>
                <StyledButton
                    to={PATH_DASHBOARD.user.account}
                    component={RouterLink} variant="contained" sx={{color: "#eaeaf4", backgroundColor: '' }}>{translate('cancelSubscription.backButton')}</StyledButton>
              </Grid>
            </Stack>
          </Grid>) :
          (<>
          <Grid item xs={6} >
            <Stack>
            <Typography sx={{fontSize: 20, fontWeight: 600, fontFamily: 'Montserrat, sans-serif;', lineHeight: '1.3'}}>
              {translate('cancelSubscription.h1')} <br/>
              {translate('cancelSubscription.h1br')}
            </Typography>
            <Typography sx={{fontSize: '13px',fontWeight: 400,lineHeight: '18px', fontFamily: 'Open Sans, sans-serif;', paddingTop: 1}}>
              {translate('cancelSubscription.sub1')} <br/>
              {translate('cancelSubscription.sub1br')}
            </Typography>

            </Stack>
            <Card sx={{padding: '32px 40px 32px 40px', marginTop: 6}}>
              <Stack>
                <Typography sx={{fontSize: 16, fontWeight: 800,lineHeight: '1.3', fontFamily: 'Montserrat, sans-serif;',}}>
                  {translate('cancelSubscription.cardTitle')}
                </Typography>
                <Typography sx={{fontSize: '13px',fontWeight: 400,lineHeight: '18px', fontFamily: 'Open Sans, sans-serif;', paddingTop: 1}}>
                  {translate('cancelSubscription.cardText')}
                </Typography>
                <Grid container justifyContent="center"  columnSpacing={1} sx={{marginTop: 5}}>
                  <Grid item xs={6}><StyledButton variant="contained" sx={{color: "#0e1215", backgroundColor: '#eaeaf4'}} onClick={()=>{handleClickOpen()}}>{translate('cancelSubscription.cancelButton')}</StyledButton></Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      to={PATH_DASHBOARD.user.account}
                      component={RouterLink} variant="contained">{translate('cancelSubscription.backButton')}</StyledButton>
                  </Grid>

                </Grid>
                <Stack direction="row">
                  <Typography component="span" sx={{fontSize: '11px',fontWeight: 400,lineHeight: '14px', fontFamily: 'Open Sans, sans-serif;', paddingTop: 1}}>{translate('cancelSubscription.cardCaption')} <br/>
                    {translate('cancelSubscription.cardCaptionBr')} <Typography component="span" variant='body1' sx={{fontSize: '11px',fontWeight: 600,lineHeight: '14px', fontFamily: 'Open Sans, sans-serif;'}}>{format(new Date(user?.dateClose), "d MMMM yyyy")}</Typography>  </Typography>
                </Stack>

              </Stack>
            </Card>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{translate('cancelSubscription.sure')}</DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {translate('cancelSubscription.happy')}
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={()=>setOpen(false)}> {translate('no')}</Button>
                <Button onClick={handleClose}>
                  {translate('yes')}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={6} sx={{marginTop: {sm:'20px', md: 0 }}}>

            <Stack>
              <Typography sx={{fontSize: 20, fontWeight: 600, fontFamily: 'Montserrat, sans-serif;', lineHeight: '1.3'}}>
                {translate('cancelSubscription.h2')}
              </Typography>
              <Stack direction='row' sx={{marginTop: 3}}>
                <div><Iconify icon="material-symbols:check" width="32px" color="#fd8017" inline /></div>

                <Stack>
                  <Typography component="span" sx={{fontSize: '16px',fontWeight: 800,lineHeight: '18px', fontFamily: 'Montserrat, sans-serif;', paddingTop: '7px'}}>
                    {translate('cancelSubscription.p1')}
                  </Typography>
                  <Typography component="span" sx={{fontSize: '13px',fontWeight: 400,lineHeight: '18px', fontFamily: 'Open Sans, sans-serif;', paddingTop: 1}}>
                    {translate('cancelSubscription.p1c')}

                  </Typography>
                </Stack>

              </Stack>
              <Stack direction='row' sx={{marginTop: 4}}>
                <div><Iconify icon="material-symbols:check" width="32px" color="#fd8017" inline /></div>

                <Stack>
                  <Typography component="span" sx={{fontSize: '16px',fontWeight: 800,lineHeight: '18px', fontFamily: 'Montserrat, sans-serif;', paddingTop: '7px'}}>
                    {translate('cancelSubscription.p2')}
                  </Typography>
                  <Typography component="span" sx={{fontSize: '13px',fontWeight: 400,lineHeight: '18px', fontFamily: 'Open Sans, sans-serif;', paddingTop: 1}}>
                    {translate('cancelSubscription.p2c')}

                  </Typography>
                </Stack>

              </Stack>

              <Stack direction='row' sx={{marginTop: 4}}>
                <div><Iconify icon="material-symbols:check" width="32px" color="#fd8017" inline /></div>

                <Stack>
                  <Typography component="span" sx={{fontSize: '16px',fontWeight: 800,lineHeight: '18px', fontFamily: 'Montserrat, sans-serif;', paddingTop: '7px'}}>
                    {translate('cancelSubscription.p3')}
                  </Typography>
                  <Typography component="span" sx={{fontSize: '13px',fontWeight: 400,lineHeight: '18px', fontFamily: 'Open Sans, sans-serif;', paddingTop: 1}}>
                    {translate('cancelSubscription.p3c')}

                  </Typography>
                </Stack>

              </Stack>
              <Stack direction='row' sx={{marginTop: 4}}>
                <div><Iconify icon="material-symbols:check" width="32px" color="#fd8017" inline /></div>

                <Stack>
                  <Typography component="span" sx={{fontSize: '16px',fontWeight: 800,lineHeight: '18px', fontFamily: 'Montserrat, sans-serif;', paddingTop: '7px'}}>
                    {translate('cancelSubscription.p4')}
                  </Typography>
                  <Typography component="span" sx={{fontSize: '13px',fontWeight: 400,lineHeight: '18px', fontFamily: 'Open Sans, sans-serif;', paddingTop: 1}}>
                    {translate('cancelSubscription.p4c')}

                  </Typography>
                </Stack>

              </Stack>
              <Stack direction='row' sx={{marginTop: 4}}>
                <div><Iconify icon="material-symbols:check" width="32px" color="#fd8017" inline /></div>

                <Stack>
                  <Typography component="span" sx={{fontSize: '16px',fontWeight: 800,lineHeight: '18px', fontFamily: 'Montserrat, sans-serif;', paddingTop: '7px'}}>
                    {translate('cancelSubscription.p5')}
                  </Typography>
                  <Typography component="span" sx={{fontSize: '13px',fontWeight: 400,lineHeight: '18px', fontFamily: 'Open Sans, sans-serif;', paddingTop: 1}}>
                    {translate('cancelSubscription.p5c')}

                  </Typography>
                  <Grid item xs={5}>
                    <StyledButton  to={PATH_DASHBOARD.user.account}
                                   component={RouterLink} variant="contained" sx={{marginTop: 4,}}>{translate('cancelSubscription.exButton')}</StyledButton>
                  </Grid>

                </Stack>

              </Stack>
            </Stack>
          </Grid></>)}
        </Grid>


      </Container>
    </>
  );
}
