const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of cLetraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  yes: `Yes`,
  no: `No`,
  home: `Home`,
  dashboard: `Dashboard`,
  user: `User`,
  account: `Account`,
  general: `General`,
  settings: `Settings`,
  username: `Username`,
  login: `Login`,
  logout: `Logout`,
  emailAddress: `Email Address`,
  password: `Password`,
  newPassword: `New Password`,
  oldPassword: `Old Password`,
  confirmNewPassword: `Confirm New Password`,
  updatePassword: `Update Password`,
  formErrorValidEmail: `Email must be a valid email address`,
  formErrorEmailRequired: `Email is required`,
  formErrorPasswordRequired: `Password is required`,
  formErrorOldPasswordRequired: `Old Password is required`,
  formErrorPasswordLength: `Password must be at least 6 characters`,
  formErrorConfirmPasswordRequired: `Confirm password is required`,
  formErrorPasswordsMatch: `Passwords must match`,
  formForgotPasswordLink: `Forgot password?`,
  snackBarChangePasswordSuccess: `Change password success!`,
  snackBarUpdateSuccess: `Update success!`,
  active: `Active`,
  inactive: `Inactive`,
  on: `On`,
  off: `Off`,
  billing: `Billing`,
  description: `Description`,
  amount: `Amount`,
  status: `Status`,
  changePassword: `Change Password`,
  changeSettings: `Change Settings`,
  setup: `Setup`,
  free: `Free`,
  invoices: `Invoices`,
  cancelSubscriptionButton: `Cancel subscription`,
  date: 'Date',
  download: 'Download',
  print: 'Print',
  close: 'Close',
  view: 'View',
  warningBanner: {
    text: 'You don’t have an active account. To activate your account renew it by clicking the button.',
    ctaButton: 'Renew',
    caption: 'Caption'
  },
  loginPage: {
    title: `Login`,
    formTitle: `New to VPN Nederland? Create account`,
    formTitleLink: `here`,
    formForgotPasswordLink: `Forgot password?`
  },
  resetPasswordPage: {
    title: `Reset Password`,
    formTitle: `Forgot Password?`,
    formSubTitle: ` Please enter the email address associated with your account and We will email you a link to
        reset your password.`,
    formTitleLink: `Return to sign in`,
    formButton: `Send Request`,
  },
  newPasswordPage: {
    title: `Reset Password`,
    formTitle: `Request sent successfully!`,
    formSubTitle: `We've sent a email with confirmation link.`,
    formSubTitle2: `Please visit the link to verify your email.`,
    formTitleLink: `Return to sign in`,
    formButton: `Send Request`,
  },
  accountPage: {
    title: `Account`,
    accountStatus: `Account status`,
    accountType: `Account type`,
    renewAccount: `Renew Account`,
    endDate: `End-date`,
    subRemaining: `Subscription remaining:`,
    autoRenew: `Auto-renewing is`,
    vpnCredentials: `VPN Credentials:`,
    setupVpn: `Setup VPN`,
    nextBillingDate: `Next billing-date`,
    endOfFreeTerm: `End of free term`,
    cancelSubscription: `Cancel Subscription`,
    activationCode: `Activation Code`,
    newsNotifications: `News and notifications e-mails`,
    recurringSubscription: `Recurring subscription`,
    tabs: {
      general: `General`,
      settings: `Settings`,
      changePassword: `Change Password`
    },
  },
  settingsPage: {
   title: `Settings`,
    formTitle: `NOTIFICATIONS & NEWSLETTERS`,
    recurringPayment: ` Recurring payment`,
    yourSubscription: `Your Subscription`,
    per: `Per`,
    curSubs: `Current subscription will continue until`,
    warn: `Your account will no longer be extended on a monthly basis.`,
    submitButton: `Save changes`,
    settingsOption1: `News and announcements`
  },
  setupVpnPage: {
    title: `Setup Vpn`,
    header: `Download VPN Netherlands for {{os}}`,
    coverH1: `Download the app at the touch of a button`,
    coverH2: `You just need to download, everything is filled in for you`,
    coverH3: `Also available for`,
    coverButtonText: 'Download for ',
    tabH1: `The activation code has already been filled in for you and you do not need VPN Netherlands alone too to download.`,
    tabH2: `Prefer to install it manually?`,
    step1: ` Download`,
    step1Caption: `The activation code is already for you filled in and you only have to use VPN Netherlands to download. Prefer to install it manually?`,
    step2: ` Copy`,
    step2Caption: `Copy the activation code and use it during the software setup.`,
    step3: ` Paste`,
    step3Caption: `Paste the copied activation code in the software to complete the installation of VPN Netherlands.`
  },
  promoPage: {

    tabH1: `Congratulations and Welcome!. Your Traffic limit has been upgraded. Enjoy 5GB monthly usage. `,
    tabH2: `You just need to download, everything is filled in for you`,
  },
  cancelSubscription: {
    h1: `If you wish to cancel your subscription, `,
    h1br:`you can do so easily below.`,
    sub1: `We're glad to have had you as a member. You're always more then welcome to come back.`,
    sub1br: `You can complete your cancellation below.`,
    cardTitle: `Cancel subscription`,
    cardText: `Are you sure you want to give up your online privacy? Then use
                   button below to complete canceling your subscription.`,
    cancelButton: `Complete cancellation`,
    backButton: `Back to website`,
    cardCaption: `The cancellation will take effect at the end of your current billing period`,
    cardCaptionBr: `on`,
    h2: `Why stay with VPN Netherlands?`,
    p1: `100% Privacy`,
    p1c: `With VPN Nederland, all worries about your online privacy are a thing of the past. No one
                     can view your data and you can move freely online.`,
    p2: `100% Safety`,
    p2c: `Because a VPN encrypts your connection, it is no longer possible for hackers 
    to break in. This will prevent them from running off with your data.`,
    p3: `100% Freedom`,
    p3c: `With VPN Nederland you can bypass geo-blocks and pretend you are in a
you are in another country, so you can enjoy 100% online freedom.`,
    p4: `100% Streaming`,
    p4c: `With VPN Nederland you get access to every (foreign) streaming service.
Added ben; Our super fast connections are perfect for streaming!`,
    p5: `100% Download`,
    p5c: `With the super fast servers and modern protocols of VPN Netherlands you can effortlessly download and stream anywhere in the world.`,
    exButton: `Stay safe online`,
    h1Canceled: `You've successfully canceled your subscription.`,
    h1CanceledFree: `You've successfully canceled your subscription.`,
    sub1Canceled: `Thanks for being a member, Come back anytime!`,
    sure: `Are you sure?`,
    happy: `We will happy to see you back anytime.`
  },
  verifyEmailPage: {
    title: ``,
    checkYourEmail: `Please check your email!`,
    inProgress: `Verification in progress...`,
    bottomText: `All information regarding your subscription and the app can be found in your inbox.`,
    success: `Email verified!`,
    alreadyVerified: 'Email already verified!',
    button: `View your account`,
    p1: `You have chosen VPN Netherlands`,
    p2: `You have successfully registered`,
    p3: `Your email address has been successfully verified`,
    p4: `Your VPN Netherlands subscription has been activated.`,
  },
  billingPage: {

    paymentFor: `Payment for`,
    invoiceNumber: `Invoice Id`,
  },
  invoiceDetailsPage: {
    title: `Invoice`,
    email: 'E-mail',
    customerNumber: 'Customer number',
    invoiceFrom: 'Invoice from',
    invoiceTo: 'Invoice to',
    invoiceDate: 'Invoice date',
    invoicePaymentMethod: 'Payment method',
    invoiceDescription: 'Description',
    invoiceProductQty: 'Qty',
    invoiceProductPrice: 'Price',
    invoiceTotal: 'Total',
    invoiceSubtotal: 'Subtotal',
    invoiceVat: 'Taxes(VAT)',
    supportDescription: 'Have a question?',
    status: {
      open: 'Open',
      paid: 'paid',
      canceled: 'canceled',
      pending: 'pending',
      chargeback: 'chargeback',
      expired: 'expired',
      failed: 'failed',
      settled: 'settled',
      partially_refunded: 'partially refunded',
      refunded: 'refunded',
      authorized: 'authorized',
      internal_error: 'internal_error',
    }
  },
  maintenancePage: {

    h1: `We're doing some maintenance.`,
    h2: `Be right back!`,
  },
  page404: {
    h1: `Oeps… de pagina die je zoekt blijkt niet te bestaan.`,
    p: `Verdwaald geraakt tijdens je zoektocht naar de Nederlandse VPN-provider? Geen zorgen, wij brengen je er heen!`,
    btn: `Breng me naar de beste VPN!`,
  }
};

export default en;
