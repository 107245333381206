import {useEffect, useState} from 'react';

// @mui
import {Box, Button, Card, Divider, Grid, Link, Stack, Typography} from '@mui/material';
// auth
import {useAuthContext} from '../../../../auth/useAuthContext';


import {format} from "date-fns";
import {Link as RouterLink} from "react-router-dom";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import {getOs} from "../../../../utils/getOs";
import {useLocales} from "../../../../locales";
import {PATH_DASHBOARD} from "../../../../routes/paths";
import {CHECKOUT_URL} from "../../../../config";



// ----------------------------------------------------------------------

export default function AccountGeneral() {
    const {translate} = useLocales();


    const {user} = useAuthContext();


    const handleRenewClick = () => {
        window.open(CHECKOUT_URL +`?email=${user?.email}`, '_blank')
    }


    const [open, setOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState('Windows');


    useEffect(() => {
        setCurrentTab(getOs());
    }, []);
    const {copy} = useCopyToClipboard()

    return (

        <Grid container spacing={3}>

            <Grid item xs={12} md={8}>
                <Card sx={{p: 3}}>
                    <Box
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >

                        <>
                            <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                {translate('username')}:
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                {user?.email}
                            </Typography>
                        </>
                        <>
                            <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                {translate('password')}:
                            </Typography>
                            <Stack spacing={2} direction="row" justifyContent="space-between">

                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                        ********
                                    </Typography>

                                    <Link to={PATH_DASHBOARD.user.changePassword}  component={RouterLink} underline="hover" sx={{ fontSize: '0.85em','&:hover': {
                                            color: "#c55c01",
                                        },}}>

                                            {translate(`changePassword`)}

                                    </Link>


                                </Stack>

                        </>
                        <>
                            <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                {translate('accountPage.accountStatus')}:
                            </Typography>
                            {user?.status ? <Typography variant="subtitle2" sx={{color: '#00AB55'}}>
                                {translate('active')}
                            </Typography> : <Typography variant="subtitle2" sx={{color: '#FF3030'}}>
                                {translate('inactive')}
                                <Button onClick={() => handleRenewClick()} size="small" color="primary" variant="outlined" sx={{ml: 1}}>

                                    {translate(`accountPage.renewAccount`)}

                                </Button>
                            </Typography>}

                        </>
                        <>
                            <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                {translate('accountPage.accountType')}:
                            </Typography>

                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                {user?.planName}
                            </Typography>


                        </>
                        {/*<>*/}
                        {/*    <Typography variant="subtitle2" sx={{mb: 0.5}}>*/}
                        {/*        /!*{translate('accountPage.recurringSubscription')}:*!/*/}
                        {/*    </Typography>*/}
                        {/*    <Stack spacing={2} direction="row" justifyContent="space-between">*/}

                        {/*        {user?.recurringSubscription ? <Typography variant="subtitle2" sx={{color: '#00AB55'}}>*/}
                        {/*            {translate('active')}*/}
                        {/*        </Typography> : <Typography variant="subtitle2" sx={{color: '#FF3030'}}>*/}
                        {/*            {translate('inactive')}*/}
                        {/*        </Typography>}*/}

                        {/*      */}


                        {/*    </Stack>*/}

                        {/*</>*/}
                        <>
                        {user?.isFreeAccount || !user?.recurringSubscription ? <Typography variant="subtitle2" sx={{mb: 0.5}}>
                            {translate('accountPage.endDate')}:
                        </Typography> :
                            <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                {translate('accountPage.nextBillingDate')}:
                            </Typography>}
                            <Stack spacing={2} direction="row" justifyContent="space-between">

                                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                    {format(new Date(user?.dateClose), "MMMM d yyyy" )}
                                </Typography>
                                <Link to={PATH_DASHBOARD.user.cancelSubscription} component={RouterLink} underline="hover" sx={{ fontSize: '0.85em', '&:hover': {
                                        color: "#c55c01"},}}>

                                    {translate(`accountPage.cancelSubscription`)}

                                </Link>
                            </Stack>

                        </>
                        {/*<>*/}
                        {/*    <Typography variant="subtitle2" sx={{mb: 0.5}}>*/}
                        {/*        {translate('accountPage.endDate')}:*/}
                        {/*    </Typography>*/}
                        {/*    <Typography variant="body2" sx={{color: 'text.secondary'}}>*/}
                        {/*        */}
                        {/*    </Typography>*/}
                        {/*</>*/}
                        {/*<>*/}
                        {/*    <Typography variant="subtitle2" sx={{mb: 0.5}}>*/}
                        {/*        {translate(`accountPage.subRemaining`)}*/}
                        {/*    </Typography>*/}
                        {/*    <Typography variant="body2" sx={{color: 'text.secondary'}}>*/}
                        {/*        {translate(`accountPage.autoRenew`)} {user?.recurringSubscription ? translate(`on`) : translate(`off`)}*/}
                        {/*    </Typography>*/}
                        {/*</>*/}

                        <>
                            <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                {translate(`accountPage.activationCode`)}:
                            </Typography>
                            <Stack spacing={2} direction="row" justifyContent="space-between">

                                    <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                                        {user?.vpnAuthCode}
                                    </Typography>

                                    <Link to={`/user/setup-vpn?token=${user?.setupVpnToken}`} target="_blank" rel="noopener" component={RouterLink} underline="hover" sx={{fontSize: '0.85em', '&:hover': {
                                    color: "#c55c01"},}}>

                                            {translate(`setup`)}

                                    </Link>



                                {/*<Stack textAlign="center" justifyContent="center">*/}
                                {/*    <IconButton onClick={()=>copy(user?.vpnAuthCode)} component={RouterLink}>*/}
                                {/*        <Iconify icon="ic:sharp-file-copy" />*/}
                                {/*    </IconButton>*/}
                                {/*</Stack>*/}

                            </Stack>


                        </>
                        {/*<>*/}
                        {/*    <Typography variant="subtitle2" sx={{mb: 0.5}}>*/}
                        {/*        {translate('accountPage.newsNotifications')}:*/}
                        {/*    </Typography>*/}
                        {/*    <Stack spacing={2} direction="row" justifyContent="space-between">*/}

                        {/*        <Typography variant="body2" sx={{color: 'text.secondary'}}>*/}
                        {/*            {translate(`on`)}*/}
                        {/*        </Typography>*/}

                        {/*        <Link to={PATH_DASHBOARD.user.settings} component={RouterLink} underline="hover" sx={{ fontSize: '0.85em', '&:hover': {*/}
                        {/*                    color: "#c55c01"},}}>*/}

                        {/*            {translate(`changeSettings`)}*/}

                        {/*        </Link>*/}


                        {/*    </Stack>*/}

                        {/*</>*/}
                    </Box>


                </Card>
            </Grid>
        </Grid>

    );
}
