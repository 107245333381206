import { Outlet } from 'react-router-dom';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config
import { HEADER } from '../../config';
// components
import Header from './Header';
import Main from "../dashboard/Main";
import TextMaxLine from "../../components/text-max-line";
import WarningBanner from "../../components/warning-banner";

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>


        {/*<Header  />*/}
      <Header isOffset />
        <WarningBanner position="relative" />
{/*<div style={{marginTop: 100}}>*/}

    <Outlet />
{/*</div>*/}

    </>
  );
}
